@import url(https://use.typekit.net/zmy6afc.css);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root {
  --plum: #621B46;
  --maroon: #C31E3C;
  --red: #EC0733;
  --grey: #5C5C5C;
  --lightgreen: #BABB13;
  --green: #008067;
  --medgrey: #d1d3d4;
  --lightgrey: #f1f1f1;
}

body {
  background-color: #f1f1f1;
  background-color: var(--lightgrey);
  color: #5C5C5C;
  color: var(--grey);
  font-family: 'komet', sans-serif;
  line-height: 1.5;
  font-weight: 400;
}

html,
body,
*:after,
*:before {
  box-sizing: border-box;
}

.App {
  min-width: 300px;
}

.checklist {
  margin: 0 auto;
  padding: 1rem;
  max-width: 54rem;
}

h1,h2,h3,h4,h5,h6 {
  font-family: 'komet', sans-serif;
  font-weight: 200;
  margin: 1rem 0;
  position: relative;
  color: #621B46;
  color: var(--plum);
}

h1 {
  padding-bottom: 1rem;
  text-align: center;
}

h1:after {
  content: "";
  background-color: #621B46;
  background-color: var(--plum);
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 0;
  height: 1px;
  width: 10rem;
}

a {
  color: #EC0733;
  color: var(--red);
}

li {
  margin-bottom: 1rem;
}

.intro {
  text-align: center;
  max-width: 45rem;
  margin: 0 auto;
}

.panelsWrapper {
  margin: 2rem 0;
}

.panel {
  background-color: white;
}

section {
  padding: 1rem;
  display: none;
}

section:last-of-type {
  margin-bottom: 0;
}

section.is-active {
  display: block;
}

button {
  background-color: #008067;
  background-color: var(--green);
  cursor: pointer;
  border: 0;
  outline: 0;
  font-family: 'komet', sans-serif;
  font-size: 1em;
  font-weight: 700;
  padding: .5rem 1rem;
  color: #5C5C5C;
  color: var(--grey);
}

.completedButton.completed {
  color: #5C5C5C;
  color: var(--grey);
  background-color: #f1f1f1;
  background-color: var(--lightgrey);
}

.completedButton.not-completed {
  color: white;
}

.panelButton {
  font-size: 1.25em;
  padding: 1rem 3rem;
  width: 100%;
  background-color: white;
  position: relative;
  border-bottom: 2px solid #f1f1f1;
  border-bottom: 2px solid var(--lightgrey);
  transition: .25s;
  text-align: left;
  display: block;
}

.panelButton.is-active {
  border-color: #008067;
  border-color: var(--green);
  color: #008067;
  color: var(--green);
}

.panelButton.completed {
  background-color: #008067;
  background-color: var(--green);
  border-color: #008067;
  border-color: var(--green);
  color: white;
}

.panelButton.completed:before,
.panelButton.not-completed:before  {
  content: "";
  background-size: 1.5rem;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  width: 1.5rem;
  height: 1.5rem;
  position: absolute;
  left: 1rem;
}

@media only screen and (min-width: 630px) {
  .panelButton.completed:before,
  .panelButton.not-completed:before {
    left: 1rem;
  }
}

.panelButton.completed:before {
  background-image: url(/static/media/checked.132a46a8.svg);
}

.panelButton.not-completed:before {
  background-image: url(/static/media/not-checked.c30180e5.svg);
}

/* Dropdown */

.dropdown:after {
  content:"";
  width: 1.5rem;
  height: 1.5rem;
  position: absolute;
  right: 1rem;
  top: 50%;
  transform: translateY(calc(-50% + .2rem));
  background-image: url(/static/media/arrow-green.75f9fa40.svg);
  background-size: 1.5rem;
  background-repeat: no-repeat;
  transition: .5s;
}

.dropdown.is-active:after {
  transform: translateY(calc(-50%)) rotate(180deg);
}

.panelButton.completed:after {
  background-image: url(/static/media/arrow.7cff7b9c.svg);
}

/* Forms */

label {
  display: block;
  padding: 2rem;
  border: 1px solid #d1d3d4;
  border: 1px solid var(--medgrey);
  margin-bottom: 1rem;
  border-radius: .25rem;
}

.linksBar {
  display: block;
  margin-top: 1rem;
}

/* Checkboxes */

/* Customize the label (the container) */
.checkBox-wrapper {
  position: relative;
  cursor: pointer;
}

/* Hide the browser's default checkbox */
.checkBox-wrapper input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkBox {
  position: absolute;
  top: 2rem;
  left: 1.5rem;
  height: 25px;
  width: 25px;
  border: 2px solid #d1d3d4;
  border: 2px solid var(--medgrey);
  border-radius: .25rem;
  overflow: hidden;
}

.checkBox-text {
  padding-left: 2rem;
}

/* On mouse-over, add a grey background color */
.checkBox-wrapper:hover input ~ .checkBox {
  border: 2px solid #d1d3d4;
  border: 2px solid var(--medgrey);
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkBox:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.checkBox-wrapper input:checked ~ .checkBox:after {
  display: block;
}

/* Style the checkmark/indicator */
.checkBox-wrapper .checkBox:after {
  content:"";
  width: 100%;
  height: 100%;
  background-image: url(/static/media/tick.e0512932.svg);
  background-repeat: no-repeat;
  background-size: cover;
}
